import Vue from "vue";
import Vuex from "vuex";
import userModule from "./modules/user";
import groupsViewModule from "./modules/groupsView";
import adminModule from "./modules/admin.js";
import institutionModule from "@/store/modules/institution";
import filtersModule from "./modules/filters";
import dashboardModule from "./modules/dashboard";

import thermometryModules from 'sw-studio-thermometry/src/store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ...thermometryModules,
    user: userModule,
    groupsView: groupsViewModule,
    admin: adminModule,
    institution: institutionModule,
    filters: filtersModule,
    dashboard: dashboardModule
  },
});
