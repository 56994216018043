import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "@/store";
import { isTokenPresent } from "@/auth/authenticationGuard";
import { isInstitutionsDataExists } from "@/utils/institutions";
import { getInstitutionData } from "@/services/Institutions";
import {haveAccessRights, haveThermometryOption} from "@/utils/navigation";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        meta: {
            noNavBar: true,
        },
        redirect: () => {
            return { name: "login" };
        },
    },
    {
        path: "/login",
        name: "login",
        beforeEnter: (to, from, next) => {
            localStorage.clear()
            next()
        },
        meta: {
            noNavBar: true,
        },
        component: () => import("../views/Login/LoginView.vue"),
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        meta: {
            noNavBar: true,
        },
        component: () => import("../views/Password/ResetPasswordView.vue"),
    },
    {
        path: "/legacy-user",
        name: "legacyUser",
        meta: {
            noNavBar: true,
        },
        component: () => import("../views/Password/LegacyUserView.vue"),
    },
    {
        path: "/registration",
        name: "registration",
        meta: {
            noNavBar: true,
        },
        component: () => import("../views/Registration/RegistrationView.vue"),
    },
    {
        path: "/registration-success",
        name: "registrationSuccess",
        meta: {
            noNavBar: true,

        },
        component: () => import("../views/Registration/RegistrationSuccessView.vue"),
    },
    {
        path: "/create-password/:token",
        name: "passwordGeneration",
        meta: {
            noNavBar: true,

        },
        beforeEnter: async (to, from, next) => {
            const token = to.params.token;
            try {
                const response = await axios.get(`${window.APP_CONFIG.URL_API}users/${token}/verify`);
                if (response.status === 200) {
                    next(
                        {
                            params:
                                { email: response.data.email }
                        }
                    );
                    try {
                        store.commit('user/checkToken', {
                            email: response.data.email,
                            token: token
                        });
                    } catch (err) {
                        return next('notFound')
                    }
                } else {
                    next({ name: "notAllowed" });
                }
            } catch (error) {
                return next('notFound');
            }
        },
        component: () => import("../views/Password/GeneratePasswordView.vue"),
    },
    {
        path: "/confirm-new-password",
        name: "confirmNewPassword",
        meta: {
            noNavBar: true,
        },
        component: () => import("../views/Password/ConfirmReinitialisationView.vue"),
    },
    {
        path: "/update-password",
        name: "updatePassword",
        meta: {
            noNavBar: true,
        },
        component: () => import("../views/Password/UpdatePasswordView.vue"),
    },
    {
        path: "/institutions",
        beforeEnter: isTokenPresent,
        name: 'institutions.index',
        component: () => import("../views/Institution/InstitutionView.vue"),
    },
    {
        path: "/create-institution",
        beforeEnter: isTokenPresent,
        name: 'institutions.create',
        component: () => import("../views/Institution/NewInstitutionView.vue"),
        meta: {
            hasParamNavBar: true,
            title: 'Les établissements',
            route: 'institutions.index'
        }
    },
    {
        path: "/edit-institution/:institutionId",
        beforeEnter: isTokenPresent,
        name: 'institutions.edit',
        component: () => import("../views/Institution/NewInstitutionView.vue"),
        meta: {
            hasParamNavBar: true,
            title: 'Les établissements',
            route: 'institutions.index'
        }
    },
    {
        path: "/institutions/:institutionId",
        beforeEnter: (to, from, next) => {
            isTokenPresent(from, next, () => {
                getInstitutionData(to, from, () => {
                    isInstitutionsDataExists(to, from, next);
                });
            });
        },

        component: () => import("../views/Institution/InstitutionLayout.vue"),
        children: [
            {
                path: "access-denied",
                name: "access.denied",
                component: () =>
                    import("../views/AccessDenied.vue"),
                meta: {},
            },
            {
                path: "myspace",
                beforeEnter: isTokenPresent,
                name: "myspace",
                component: () => import("../views/User/MySpaceView.vue"),
            },
            {
                path: "dashboard",
                component: () =>
                    import("../views/Institution/Dashboard/DashboardDefaultView.vue"),
                meta: {},
                beforeEnter: haveAccessRights,
                children: [
                    {
                        path: "",
                        name: "routes.names.dashboard",
                        component: () =>
                            import("../views/Institution/Dashboard/DashboardView.vue"),
                        meta: {
                            logo: "/menu/bord.svg",
                        },
                    },
                ]
            },
            {
                path: "location",
                beforeEnter: haveAccessRights,
                name: "routes.names.location",
                component: () =>
                    import("../views/Institution/Localisation/LocalisationView.vue"),
                meta: {
                    logo: "/menu/localisation.svg",
                },
            },
            {
                path: "pairing",
                beforeEnter: haveAccessRights,
                component: () =>
                    import("../views/Pairing/PairingDefaultView.vue"),
                meta: {},
                children: [
                    {
                        path: "",
                        name: "routes.names.pairing",
                        component: () =>
                            import("../views/Pairing/PairingView.vue"),
                        meta: {
                            logo: "/menu/associer.svg",
                        },
                    },
                    {
                        path: "touch",
                        name: "routes.names.pairing.touch",
                        component: () =>
                            import("../views/Pairing/Touch/TouchPairingView.vue"),
                        meta: {},
                    },
                    {
                        path: "touch/failure",
                        name: "routes.names.pairing.touch.failure",
                        component: () =>
                            import("../views/Pairing/Touch/TouchPairingFailureView.vue"),
                        meta: {},
                    },
                    {
                        path: "search",
                        name: "routes.names.pairing.search",
                        component: () =>
                            import("../views/Pairing/Search/PairingSearchView.vue"),
                        meta: {},
                    },
                    {
                        path: ":sensorId/attach",
                        name: "routes.names.pairing.attach",
                        component: () => import("../views/Pairing/AttachSensorToEquipmentView.vue")
                    },
                    {
                        path: ":sensorId/paired",
                        name: "routes.names.pairing.paired",
                        component: () => import("../views/Pairing/PairedSensorToEquipmentView.vue")
                    }
                ]
            },
            {
                path: "reports-alerts",
                beforeEnter: haveAccessRights,
                component: () =>
                    import("../views/ReportsAlerts/ReportsAlertsDefaultView.vue"),
                meta: {},
                children: [
                    {
                        path: "",
                        name: "routes.names.reports_alerts",
                        component: () =>
                            import("../views/ReportsAlerts/ReportsAlertsView.vue"),
                        meta: {
                            logo: "/menu/rapports.svg",
                        },
                    },
                ]
            },
            {
                path: "thermometry",
                beforeEnter: haveThermometryOption,
                name: "routes.names.thermometry",
                component: () => 
                    import ("sw-studio-thermometry/src/views/ThermometryAlertsView.vue"),
                meta: {logo: "/menu/thermometry.svg",}
            },
            {
                path: "thermometry/alerts/:equipmentId/:alertId",
                beforeEnter: haveThermometryOption,
                name: "routes.names.thermometry.alert",
                component: () => 
                    import ("sw-studio-thermometry/src/views/ThermometryAlertFormView.vue"),
                meta: {},
            },
            {
                path: "thermometry/graph/:equipmentId",
                beforeEnter: haveThermometryOption,
                name: "routes.names.thermometry.graph",
                component: () =>
                    import("../views/Thermometry/ThermometryEquipmentDetail.vue"),
                meta: {
                    hasParamNavBar: true,
                    title: 'Détail équipement',
                    route: 'routes.names.thermometry'
                }
            },
            //Watcher in ParameterNav.View for parameters
            {
                path: "parameters",
                beforeEnter: haveAccessRights,
                name: 'routes.names.parameters',
                component: () => import("../views/Parameters/ParametersView.vue"),
                meta: {
                    logo: "/menu/parameters.svg",
                },
            },
            {
                path: "parameters/buildings",
                name: "parameters.buildings",
                component: () => import("../views/Parameters/Buildings/ParametersBuildingsView.vue"),
                meta: {
                    hasParamNavBar: true,
                    title: 'Bâtiment',
                    route: 'routes.names.parameters',
                    hasBreadCrumb: true,
                }
            },
            {
                path: 'parameters/buildings/:buildingId/stairs',
                name: "parameters.stairs",
                component: () => import("../views/Parameters/Buildings/Stairs/StairsView.vue"),
                meta: {
                    hasParamNavBar: true,
                    title: 'Étages',
                    route: 'routes.names.parameters',
                    hasBreadCrumb: true,

                }
            },
            {
                path: 'parameters/buildings/:buildingId/stairs/:stairId',
                name: "parameters.rooms",
                component: () => import("../views/Parameters/Buildings/Stairs/Rooms/RoomsView.vue"),
                meta: {
                    hasParamNavBar: true,
                    title: 'Pièces',
                    route: 'routes.names.parameters',
                    hasBreadCrumb: true,
                }
            },
            {
                path: 'parameters/users',
                name: "parameters.users",
                component: () => import("../views/Parameters/Users/UsersView.vue"),
                meta: {
                    title: 'Utilisateurs',
                    route: 'routes.names.parameters',
                    hasParamNavBar: true,
                    hasBreadCrumb: false,
                }
            },
            {
                path: 'parameters/services',
                name: "parameters.services",
                component: () => import("../views/Parameters/Services/ServicesView.vue"),
                meta: {
                    title: 'Services',
                    route: 'routes.names.parameters',
                    hasParamNavBar: true,
                    hasBreadCrumb: false,
                }
            },
            {
                path: 'parameters/equipments',
                name: "parameters.equipments",
                component: () => import("../views/Parameters/Equipments/EquipmentsView.vue"),
                meta: {
                    title: 'Équipements',
                    route: 'routes.names.parameters',
                    hasParamNavBar: true,
                    hasBreadCrumb: false,
                }
            },
            {
                path: 'parameters/infrastructures',
                component: () => import("../views/Parameters/Infrastructure/InfrastructureLayoutView.vue"),
                meta: {
                    title: 'Infrastructure',
                    route: 'routes.names.parameters',
                    hasParamNavBar: true,
                    hasBreadCrumb: false,
                },
                children: [
                    {
                        path: 'sensors',
                        name: "parameters.infrastructures.sensors",
                        component: () => import("../views/Parameters/Infrastructure/InfrastructureSensorsView.vue"),
                        meta: {
                            hasParamNavBar: true,
                            route: 'routes.names.parameters',
                            hasBreadCrumb: false,
                            title: 'Capteurs'
                        }
                    },
                    {
                        path: 'gateways',
                        name: "parameters.infrastructures.gateways",
                        component: () => import("../views/Parameters/Infrastructure/InfrastructureGatewaysView.vue"),
                        meta: {
                            hasParamNavBar: true,
                            route: 'routes.names.parameters',
                            hasBreadCrumb: false,
                            title: 'Passerelles'
                        }
                    }
                ]
            }
        ],
    },
    {
        path: "/not-allowed",
        name: "notAllowed",
        component: () => import("@/components/NotAllowed.vue"),
        meta: {
            noNavBar: true,
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("@/components/NotFound.vue"),
        meta: {
            noNavBar: true,
        },
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
