import store from "@/store";

const institutionState = {
    institutionName: null,
    institutionId: null,
    userRole: null,
    group:null,
    address:null,
    addressCode:null,
    addressCity:null,
    buildings:[],
    equipments:[],
    error: null,
    thermometry: {}
};

const institutionModule = {
    namespaced: true,
    state: institutionState,
    getters: {},
    actions: {

    },
    mutations: {
        selectInstitution(state, data) {
            state.userRole = data.institution.role ?? 'UTILISATEUR';
            state.institutionId = data.institution.id;
            state.institutionName = data.institution.name;
            state.group = data.institution.group;
            store.commit('user/updateUserRole', state.userRole)
        },
        fillInstitution(state,institution){
            state.userRole = institution.role;
            state.institutionId = institution.id;
            state.institutionName = institution.name;
            state.group = institution.group;
            state.address = institution.address;
            state.addressCity = institution.addressCity;
            state.addressCode = institution.addressCode;
            state.buildings = institution.buildings;
            state.equipments = institution.equipments;
            if(institution.institutionsOptions && institution.institutionsOptions.thermometrie) {
                state.thermometry = true;
            }else {
                state.thermometry = false;
            }
        }
    },
    modules: {},
};

export default institutionModule;
