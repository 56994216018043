
const userThermometryState = {
    acquitToken: null,
};

const userThermometryModule = {
    namespaced: true,
    state: userThermometryState,
    getters: {},
    actions: {
    },
    mutations: {
        resetUserThermometryState(state) {
            state.acquitToken = null;
        },
        updateAcquitToken(state, data) {
            state.acquitToken = data;
        },
    },
    modules: {},
};

export default userThermometryModule;