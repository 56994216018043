import store from "@/store";
import { getRequest } from "./utility";

const INSTITUTIONS_URL = window.APP_CONFIG.URL_API + "institutions";

export const getPlan = (institutionId, buildingId, levelId, successCallback) => {
    getRequest(
        `${INSTITUTIONS_URL}/${institutionId}/buildings/${buildingId}/levels/${levelId}/plan`,
        successCallback
    );
}

export const getBuildingsAndLevels = (institutionId, successCallback) => {
    getRequest(
        `${INSTITUTIONS_URL}/${institutionId}/list-buildings-and-levels`,
        successCallback
    );
}

export const getRoomsForLevel = (institutionId, buildingId, levelId, successCallback) => {
    getRequest(
        `${INSTITUTIONS_URL}/${institutionId}/buildings/${buildingId}/levels/${levelId}/rooms`,
        successCallback
    );
}

export const isInstitutionsDataExists = (to, from, next) => {
    let isData = true;
    const buildingId = to.params.buildingId
    const stairId = to.params.stairId
    const institutionData = store.state.institution
    const building = institutionData.buildings.find((element) => {
        return parseInt(element.id) === parseInt(buildingId)
    });
    if(buildingId !== undefined){
        if (!building) {
            isData = false;
        } else {
            if(stairId !== undefined){
                const level = building.levels.find((element) => {
                    return parseInt(element.id) === parseInt(stairId);
                })
                if (!level) {
                    isData = false
                }
            }
        }
    }
    isData ? next() : next(false);
}